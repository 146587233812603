import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Briefcase, MapPin, PoundSterling, Clock, Check, ArrowLeft } from 'lucide-react';
import ApplicationForm from './ApplicationForm';
import MarketingPopup from './MarketingPopup';
import { jobs } from './JobsData';

const SingleJobView = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [isApplyOpen, setIsApplyOpen] = useState(false);
  const [showMarketingPopup, setShowMarketingPopup] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);

  // Load applied jobs from localStorage
  useEffect(() => {
    const appliedJobsCookie = localStorage.getItem('appliedJobs');
    if (appliedJobsCookie) {
      setAppliedJobs(JSON.parse(appliedJobsCookie));
    }
  }, []);

  // Find the job based on slug
  useEffect(() => {
    const foundJob = jobs.find(j => j.slug === jobId);
    if (foundJob) {
      setJob(foundJob);
    } else {
      navigate('/partnerjobs');
    }
  }, [jobId, navigate]);

  if (!job) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  const isApplied = appliedJobs.includes(job.id);

  const handleApplicationSubmit = (jobId) => {
    const updatedAppliedJobs = [...appliedJobs, jobId];
    setAppliedJobs(updatedAppliedJobs);
    localStorage.setItem('appliedJobs', JSON.stringify(updatedAppliedJobs));
    setShowMarketingPopup(true);
    setIsApplyOpen(false);
  };

  return (
    <>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Back button */}
        <Link
          to="/partnerjobs"
          className="inline-flex items-center text-indigo-600 hover:text-indigo-700 mb-6 group"
        >
          <ArrowLeft size={20} className="mr-2 group-hover:-translate-x-1 transition-transform" />
          Back to All Jobs
        </Link>

        <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
          <div className="mb-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">{job.title}</h1>
            <p className="text-xl text-gray-600">{job.company}</p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
            <div className="flex items-center gap-2 text-gray-600">
              <MapPin size={20} />
              <span>{job.location}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <PoundSterling size={20} />
              <span>{job.salary}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Briefcase size={20} />
              <span>{job.type}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Clock size={20} />
              <span>{job.posted}</span>
            </div>
          </div>

          <div className="prose max-w-none mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Job Description</h2>
            <p className="text-gray-700">{job.description}</p>
            
            <h2 className="text-xl font-semibold text-gray-900 mt-6 mb-4">Requirements</h2>
            <ul className="space-y-2">
              {job.requirements.map((req, index) => (
                <li key={index} className="flex items-start gap-2 text-gray-700">
                  <span className="mt-1.5">•</span>
                  <span>{req}</span>
                </li>
              ))}
            </ul>
          </div>

          {isApplied ? (
            <div className="bg-green-50 text-green-700 px-4 py-3 rounded-md flex items-center gap-2">
              <Check size={20} />
              You have already applied to this position
            </div>
          ) : (
            <div className="flex gap-4">
              <button
                onClick={() => setIsApplyOpen(true)}
                className="px-6 py-3 bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium rounded-md hover:from-violet-700 hover:to-indigo-700 transition-colors"
              >
                Apply Now
              </button>
              <Link
                to="/partnerjobs"
                className="px-6 py-3 border border-gray-300 text-gray-700 font-medium rounded-md hover:bg-gray-50 transition-colors"
              >
                View Other Jobs
              </Link>
            </div>
          )}
        </div>

        {isApplyOpen && (
          <ApplicationForm
            job={job}
            onClose={() => setIsApplyOpen(false)}
            onSubmit={handleApplicationSubmit}
          />
        )}
      </div>

      <MarketingPopup 
        isOpen={showMarketingPopup}
        onClose={() => setShowMarketingPopup(false)}
      />
    </>
  );
};

export default SingleJobView;