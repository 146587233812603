export const jobs = [
  {
    id: 1,
    slug: "supply-chain-graduate-programme",
    title: "Supply Chain Graduate Programme",
    company: "Confidential Company",
    location: "Clevedon, UK",
    salary: "Competitive starting salary",
    type: "2-Year Graduate Programme",
    posted: "Ongoing",
    description: "A two-year rotational Graduate Development Programme in procurement and supply chain, offering unique opportunities to work across multiple functions. Engage in critical projects including supplier negotiations, inventory management, continuous improvement, and supporting production operations in a global technology business.",
    requirements: [
      "Minimum 2:1 Bachelor's degree in Procurement, Business, Manufacturing, or Engineering",
      "Demonstrated commitment, interaction, and innovation",
      "Ambition and enthusiasm for continuous learning",
      "Ability to work collaboratively and be mobile",
      "Strong analytical and relationship-building skills"
    ]
  },
  {
    id: 2,
    slug: "mechanical-engineering-design-graduate-programme",
    title: "Mechanical Engineering Design Graduate Programme",
    company: "Confidential Company",
    location: "Midlands and Wales, UK",
    salary: "£29,056 per annum",
    type: "27-month Programme",
    posted: "Rolling Basis",
    description: "The Mechanical Engineering Design Graduate Programme offers a unique opportunity to work on critical infrastructure projects in the water and wastewater industry. Through three 9-month placements, graduates will contribute to the design and delivery of pipelines, treatment facilities, and innovative engineering solutions. Work alongside experts to address challenges like climate change, water shortages, and sustainability, while developing skills in project management, stakeholder engagement, and cutting-edge technology.",
    requirements: [
      "Degree in IMechE accredited Mechanical Engineering (BEng or MEng)",
      "Knowledge of fluid mechanics, materials selection, and pipework design",
      "Strong problem-solving skills and the ability to manage and influence stakeholders",
      "Excellent communication skills and a collaborative mindset",
      "Full UK driving license and access to a vehicle for site visits",
      "Interest in environmental sustainability and innovative engineering practices"
    ]
  },
  {
    id: 3,
    slug: "graduate-cyber-security-analyst",
    title: "Graduate Cyber Security Analyst",
    company: "Confidential Company",
    location: "UK",
    salary: "Competitive salary",
    type: "Graduate Role",
    posted: "Ongoing",
    description: "Protect information systems and networks from security breaches and cyber threats by responding to security events, conducting improvements and assessments. The role involves continuous monitoring, incident response, security tooling maintenance, threat intelligence, reporting, and contributing to security policy development.",
    requirements: [
      "Passion for cyber security and technology",
      "Knowledge of security methodologies and technical solutions",
      "Understanding of TCP/IP Protocols, network analysis, and security applications",
      "Strong analytical and problem-solving skills",
      "Excellent communication skills",
      "Hands-on experience with cloud platforms and operating systems",
      "Security certification or similar qualification"
    ]
  },
  {
    id: 4,
    slug: "data-analyst-graduate-programme",
    title: "Data Analyst Graduate Programme",
    company: "Confidential Company",
    location: "UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "A comprehensive two-year programme focusing on developing specialist expertise in Data and Analytics. Collaborate with stakeholders across the business to solve key issues, communicate results to decision-makers, and stay updated with data science trends.",
    requirements: [
      "Minimum 2:1 degree in Data Science, statistics, or related field",
      "Strong communication and relationship-building skills",
      "Positive and determined attitude",
      "Analytical skills with strong numerical abilities",
      "Familiarity with coding languages",
      "Full UK driving license",
      "Problem-solving abilities"
    ]
  },
  {
    id: 5,
    slug: "entry-level-react-developer",
    title: "Entry Level React Developer",
    company: "Confidential Company",
    location: "Manchester, UK",
    salary: "Competitive salary + benefits",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "Join our dynamic development team as an Entry Level React Developer. You'll work on cutting-edge web applications, collaborate with experienced developers, and contribute to the full development lifecycle. This role offers extensive training and mentorship opportunities.",
    requirements: [
      "Bachelor's degree in Computer Science, Software Engineering, or related field",
      "Basic knowledge of React.js and modern JavaScript (ES6+)",
      "Understanding of HTML5, CSS3, and responsive design principles",
      "Familiarity with version control systems (Git)",
      "Strong problem-solving abilities and eagerness to learn",
      "Good communication skills and ability to work in a team",
      "Portfolio of personal or academic projects (preferred)"
    ]
  },
  {
    id: 6,
    slug: "graduate-graphic-designer",
    title: "Graduate Graphic Designer",
    company: "Confidential Company",
    location: "Bristol, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "An exciting opportunity for creative graduates to join our design team. You'll work on diverse projects across digital and print media, developing your skills in branding, typography, and digital design while working with senior designers and various stakeholders.",
    requirements: [
      "Bachelor's degree in Graphic Design, Visual Communication, or related field",
      "Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign)",
      "Understanding of UI/UX design principles",
      "Strong portfolio demonstrating creative abilities",
      "Knowledge of current design trends and best practices",
      "Excellent attention to detail and time management skills",
      "Good communication and presentation skills"
    ]
  },
  {
    id: 7,
    slug: "business-analyst-early-careers",
    title: "Business Analyst Early Careers",
    company: "Confidential Company",
    location: "Edinburgh, UK",
    salary: "Competitive salary + benefits",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "A comprehensive programme designed to develop future business analysts. You'll gain experience in requirements gathering, process improvement, stakeholder management, and project delivery while working across different business units.",
    requirements: [
      "2:1 or above in Business, Economics, or related analytical discipline",
      "Strong analytical and problem-solving skills",
      "Excellence in Excel and data analysis",
      "Understanding of business processes and system development lifecycles",
      "Strong written and verbal communication skills",
      "Ability to work with stakeholders at all levels",
      "Keen interest in technology and business transformation"
    ]
  },
  {
    id: 8,
    slug: "automotive-engineer-graduate-scheme",
    title: "Automotive Engineer Graduate Scheme",
    company: "Confidential Company",
    location: "Coventry, UK",
    salary: "Competitive salary",
    type: "16-month Programme",
    posted: "Ongoing",
    description: "An intensive 16-month programme in automotive engineering, focusing on electric vehicle development, powertrain systems, and sustainable mobility solutions. Rotate through key engineering departments while working on cutting-edge automotive technologies.",
    requirements: [
      "2:1 or above in Automotive Engineering, Mechanical Engineering, or related field",
      "Understanding of automotive systems and electric vehicle technologies",
      "Knowledge of CAD software and simulation tools",
      "Strong analytical and problem-solving abilities",
      "Good project management skills",
      "Full UK driving license",
      "Passion for automotive innovation and sustainability"
    ]
  },
  {
    id: 9,
    slug: "asset-pricing-graduate",
    title: "Asset Pricing Graduate",
    company: "Confidential Company",
    location: "London, UK",
    salary: "Competitive salary + benefits",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "Join our asset pricing team to develop expertise in financial markets, risk assessment, and pricing strategies. Work with experienced professionals on complex financial instruments while gaining exposure to various asset classes.",
    requirements: [
      "2:1 or above in Mathematics, Physics, Economics, or related quantitative field",
      "Strong mathematical and statistical skills",
      "Programming knowledge (Python, R, or similar)",
      "Understanding of financial markets and instruments",
      "Excellent analytical and problem-solving abilities",
      "Strong attention to detail",
      "Interest in financial markets and investment strategies"
    ]
  },
  {
    id: 10,
    slug: "data-engineering-graduate",
    title: "Data Engineering Graduate",
    company: "Confidential Company",
    location: "Leeds, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "A comprehensive programme focusing on big data technologies, data pipeline development, and data architecture. Work on large-scale data projects while learning about modern data engineering practices and tools.",
    requirements: [
      "2:1 or above in Computer Science, Data Science, or related technical field",
      "Knowledge of SQL and database systems",
      "Basic programming skills in Python or Java",
      "Understanding of data structures and algorithms",
      "Familiarity with big data technologies (Hadoop, Spark) preferred",
      "Strong analytical and problem-solving skills",
      "Good communication abilities"
    ]
  },
  {
    id: 11,
    slug: "network-engineering-graduate",
    title: "Network Engineering Graduate",
    company: "Confidential Company",
    location: "Birmingham, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "Develop expertise in network design, implementation, and maintenance while working with the latest networking technologies. Gain hands-on experience with cloud infrastructure, security, and network optimization.",
    requirements: [
      "2:1 or above in Computer Networks, Computer Science, or related field",
      "Understanding of networking protocols and architectures",
      "Knowledge of network security principles",
      "Familiarity with cloud platforms (AWS, Azure)",
      "Strong troubleshooting skills",
      "Good communication and team working abilities",
      "Interest in emerging network technologies"
    ]
  },
  {
    id: 12,
    slug: "global-strategy-analyst",
    title: "Global Strategy Analyst",
    company: "Confidential Company",
    location: "Cambridge, UK",
    salary: "Competitive salary + benefits",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "Join our strategy team to work on global business initiatives, market analysis, and strategic planning. Develop skills in business strategy, market research, and strategic implementation while working with senior leadership.",
    requirements: [
      "2:1 or above in Business, Economics, or related field",
      "Strong analytical and research skills",
      "Excellence in data analysis and presentation",
      "Understanding of global markets and business trends",
      "Proficiency in Excel and PowerPoint",
      "Excellent written and verbal communication",
      "Ability to work in a fast-paced environment"
    ]
  },
  {
    id: 13,
    slug: "marketing-graduate",
    title: "Marketing Graduate",
    company: "Confidential Company",
    location: "Glasgow, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "A comprehensive marketing programme covering digital marketing, brand management, campaign planning, and marketing analytics. Work across different marketing functions while developing practical marketing skills.",
    requirements: [
      "2:1 or above in Marketing, Business, or related field",
      "Understanding of digital marketing principles",
      "Creative mindset with analytical abilities",
      "Knowledge of social media platforms and trends",
      "Strong communication and presentation skills",
      "Experience with marketing tools and analytics platforms preferred",
      "Excellent project management abilities"
    ]
  },
  {
    id: 14,
    slug: "seo-specialist-early-careers",
    title: "SEO Specialist - Early Careers",
    company: "Confidential Company",
    location: "Newcastle, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "Join our digital marketing team as an SEO specialist, working on website optimization, content strategy, and search performance improvement. Learn about the latest SEO techniques while working on real-world projects.",
    requirements: [
      "2:1 or above in Digital Marketing, Computer Science, or related field",
      "Understanding of SEO principles and best practices",
      "Knowledge of HTML and basic web technologies",
      "Familiarity with SEO tools and analytics platforms",
      "Strong analytical and problem-solving skills",
      "Excellent written communication abilities",
      "Interest in digital marketing and web technologies"
    ]
  },
  {
    id: 15,
    slug: "social-media-manager-graduate",
    title: "Social Media Manager - Graduate",
    company: "Confidential Company",
    location: "Brighton, UK",
    salary: "Competitive salary",
    type: "2-Year Graduate Scheme",
    posted: "Ongoing",
    description: "A dynamic role managing social media strategies, content creation, and community engagement. Develop skills in social media marketing, analytics, and brand communication while working with creative teams.",
    requirements: [
      "2:1 or above in Marketing, Communications, or related field",
      "Strong understanding of social media platforms and trends",
      "Creative content creation abilities",
      "Experience with social media management tools",
      "Excellent written and verbal communication skills",
      "Understanding of social media analytics",
      "Good project management and organizational skills"
    ]
  }
];