import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Check, Star, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const MarketingPopup = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleYesClick = () => {
    onClose();
    navigate('/bookameeting');
  };

  const handleNoClick = () => {
    onClose();
    navigate('/partnerjobs');
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    handleNoClick();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-[60] flex items-center justify-center p-4"
          onClick={(e) => e.stopPropagation()} // Prevent clicks from bubbling up
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-xl shadow-2xl overflow-hidden max-w-4xl w-full max-h-[90vh] overflow-y-auto"
          >
            {/* Header */}
            <div className="relative bg-gradient-to-r from-violet-600 to-indigo-600 p-6 text-white">
              
              <h2 className="text-2xl sm:text-3xl font-bold text-center mb-2">
                Take Your Career to the Next Level
              </h2>
              <p className="text-white/90 text-center">
                Join thousands of international students who've successfully secured sponsored positions in the UK
              </p>
            </div>

            <div className="p-6 space-y-8">
              {/* Stats */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-indigo-50 p-4 rounded-lg text-center transform hover:scale-105 transition-transform">
                  <div className="text-3xl font-bold text-indigo-600 mb-1">93%</div>
                  <div className="text-sm text-gray-600">Success Rate</div>
                </div>
                <div className="bg-indigo-50 p-4 rounded-lg text-center transform hover:scale-105 transition-transform">
                  <div className="text-3xl font-bold text-indigo-600 mb-1">1,500+</div>
                  <div className="text-sm text-gray-600">Partner Employers</div>
                </div>
                <div className="bg-indigo-50 p-4 rounded-lg text-center transform hover:scale-105 transition-transform">
                  <div className="text-3xl font-bold text-indigo-600 mb-1">200+</div>
                  <div className="text-sm text-gray-600">Success Stories</div>
                </div>
              </div>

              {/* Services */}
              <div>
                <h3 className="text-lg font-semibold mb-4">How does it work:</h3>
                <div className="grid gap-3">
                  {[
                    'Customized ATS-Friendly CVs with 80+ Score Guaranteed',
                    'Professional LinkedIn Profile Enhancement, More reach by Employers',
                    'We Apply to the Visa-Sponsored Jobs on Your Behalf',
                    'Comprehensive Interview Preparation & Mock Interviews',
                    'Dedicated Career Consultant Throughout Your Journey',
                    'Access to Our Premium AI Powered CV/Cover Letter Writer and Our Best in Class Job Board'
                  ].map((service, index) => (
                    <div key={index} className="flex items-start gap-2 group">
                      <Check className="text-green-500 mt-1 flex-shrink-0 group-hover:scale-110 transition-transform" size={18} />
                      <span className="text-gray-700">{service}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Trustpilot Reviews */}
<div className="bg-gray-50 p-6 rounded-lg text-center">
  <div className="flex justify-center mb-3">
    {[1, 2, 3, 4, 5].map((star) => (
      <Star
        key={star}
        className="text-green-500 fill-green-500"
        size={24}
      />
    ))}
  </div>
  
  <span className="block text-gray-700 font-medium mb-4">
    Rated "Great" on Trustpilot
  </span>
  
  <p className="text-gray-600 italic mb-3 max-w-2xl mx-auto">
    "I’m not one to usually to leave reviews, but I’d really like to thank the team at UKworkx.

I had no clue how to navigate the UK job market as I was an international student who came here for my masters. I had used career consulting services before which were useless and a waste of money so I was hesitant at the start. After talking to one of their consultants they clearly answered my questions and reassured me. From writing professional ATS friendly CVs to applying for finding and applying for jobs me every week. The entire process was smooth and efficient. Their Elite package included everything I needed help with

What I found most useful was there 1:1 interview preps. Even when I would tell them I had an interview coming up on very short notice they would always make sure to have proper meetings with me. We would go through mocks interviews, they’d give me personalised guides which mentioned everything from how to prepare, what questions will come up, example answers using STAR technique and would even tell me what questions I should ask the recruiter and how to follow up with with them after the interview.

All in all I’m really happy. I want to personally thank Lalit, Evelyn and the entire time at UKWORKX for helping me finding a sponsored job. You guys really saved me"
  </p>
  
  <div className="text-sm font-medium text-indigo-600">
    - Aleena Raju
  </div>

  <div className="text-lg pt-5 font-medium text-indigo-600">
    RATED "GREAT" BY MANY OTHER INTERNATIONAL STUDENTS LIKE YOU!
  </div>

  
</div>

              {/* Call to Action */}
              <div className="text-center space-y-4">
                <h3 className="text-lg font-semibold">
                  Ready to accelerate your career journey?
                </h3>
                <p className="text-gray-600">
                  Book a free consultation to discuss how we can help you secure your dream role
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <button
                    onClick={handleYesClick}
                    className="px-6 py-3 bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium rounded-md hover:from-violet-700 hover:to-indigo-700 transition-all transform hover:scale-105 flex items-center justify-center gap-2 sm:order-2"
                  >
                    Yes, Book My Free Consultation
                    <ArrowRight size={18} />
                  </button>
                  <button
                    onClick={handleNoClick}
                    className="px-6 py-3 border border-gray-300 text-gray-700 font-medium rounded-md hover:bg-gray-50 transition-all hover:border-gray-400 sm:order-1"
                  >
                    Continue Job Search
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default MarketingPopup;