import HeroSection from '../features/herosection/HeroSection.js';
import Stats from '../features/stats/Stats.js'
import LogoClouds from '../features/logoclouds/LogoClouds.js'
import PricingShort from '../features/pricing/PricingShort.js'
import FaqSection from '../features/faqsection/FaqSection.js';
import CookieBanner from '../features/banners/CookieBanner.js';
import Footer from '../features/footer/Footer.js';
import UniLogos from '../features/unilogos/UniLogos.js';
import ContactForm from "../features/contactform/ContactForm.js"
import React, { useState } from 'react';
import TopBanner from '../features/banners/TopBanner.js';
import Navbar from "../features/navbar/Navbar.js"
import VideoTestimonials from "../features/testimonials/VideoTestimonials.js"
import { StepChart } from '../features/stepchart/StepChart.js';
import { CheckAts } from '../features/banners/CheckAts.js';
import BarGraph from '../features/chart/BarGraph.js';
import MeetOurFounders from '../features/founders/Founders.js';
import Trustpilot from "../features/trustpilot/Trustpilot.js"
export default function Home() {
    const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
  return (
    <>
    
    <Navbar onContactFormOpen={handleContactFormOpen}/>
    <HeroSection onContactFormOpen={handleContactFormOpen}/>
    <VideoTestimonials />
    <Trustpilot/>
    <StepChart />
    <CheckAts />
    <BarGraph />
    <Stats />
    <LogoClouds />
    <UniLogos />
    <PricingShort/>
    <FaqSection />
    <MeetOurFounders/>
    <Footer />
    {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
    <CookieBanner />
    <TopBanner onContactFormOpen={handleContactFormOpen} />
    </>
  )
}