import React from 'react'
import JobData from '../features/jobdata/JobData'
import Navbar from '../features/navbar/Navbar'
import Footer from "../features/footer/Footer"
import ContactForm from "../features/contactform/ContactForm.js"
import { useState } from 'react'

function JobDataPage() {

  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };

  return (
    <div>
    <Navbar onContactFormOpen={handleContactFormOpen}/>
    {showContactForm && (
            <ContactForm
              onClose={handleContactFormClose}
              selectedAction={selectedAction}
            />
          )}
    <JobData/>
    <Footer/>
    </div>
  )
}

export default JobDataPage