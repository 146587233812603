import React from 'react';
import review1 from "../../images/fiver1.png";
import review2 from "../../images/fiver2.png";
import review3 from "../../images/fiver3.png";
import review4 from "../../images/fiver4.png";
import review5 from "../../images/fiver5.png";

const TrustpilotReviews = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            We are rated "Great" on Trustpilot
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Our expertise lies in assisting international students and Post study work/Graduate visa holders secure sponsorship-eligible employment. With a remarkable 93% client satisfaction rate, our proven track record speaks for itself.
          </p>
        </div>

        <div className="max-w-5xl mx-auto">
          {/* Top Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img 
                src={review1} 
                alt="Trustpilot review" 
                className="w-full h-auto object-cover"
              />
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img 
                src={review2} 
                alt="Trustpilot review" 
                className="w-full h-auto object-cover"
              />
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img 
                src={review3} 
                alt="Trustpilot review" 
                className="w-full h-auto object-cover"
              />
            </div>
          </div>

          {/* Bottom Row - Centered */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="lg:col-start-2 bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img 
                src={review4} 
                alt="Trustpilot review" 
                className="w-full h-auto object-cover"
              />
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img 
                src={review5} 
                alt="Trustpilot review" 
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustpilotReviews;