// src/components/admin/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export default function Dashboard() {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('bookingDate');
  const [selectedDate, setSelectedDate] = useState('');
  const [isEmailSending, setIsEmailSending] = useState({});

  useEffect(() => {
    fetchMeetings();
  }, [sortBy, selectedDate]);

  const fetchMeetings = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      const response = await fetch('https://ukworkx.com/api/admin/meetings?' + new URLSearchParams({
        sortBy,
        date: selectedDate,
        search: searchTerm
      }), {
        headers: {
          'x-auth-token': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch meetings');
      }

      const data = await response.json();
      setMeetings(data);
    } catch (error) {
      console.error('Error fetching meetings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailAction = async (meetingId, emailType) => {
    setIsEmailSending({ ...isEmailSending, [meetingId]: true });
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch('https://ukworkx.com/api/admin/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({
          meetingId,
          emailType
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Email sent successfully!');
      
      // Refresh meetings list if the email type was 'cancel' or 'noShow'
      if (emailType === 'cancel' || emailType === 'noShow') {
        await fetchMeetings();
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    } finally {
      setIsEmailSending({ ...isEmailSending, [meetingId]: false });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin');
  };

  const filteredMeetings = meetings.filter(meeting => 
    meeting.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    meeting.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    meeting.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    meeting.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getMeetingStatusColor = (status) => {
    switch (status) {
      case 'scheduled':
        return 'bg-green-100 text-green-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'no-show':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold">Admin Dashboard</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleLogout}
                className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          {/* Search and Filter Controls */}
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
            <input
              type="text"
              placeholder="Search by name, email or phone..."
              className="w-full sm:w-64 px-4 py-2 border rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="flex space-x-4">
              <select
                className="px-4 py-2 border rounded-md"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="bookingDate">Sort by Booking Date</option>
                <option value="meetingDate">Sort by Meeting Date</option>
              </select>
              <input
                type="date"
                className="px-4 py-2 border rounded-md"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
          </div>

          {/* Meetings Table */}
          {loading ? (
            <div className="text-center py-4">Loading...</div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow rounded-lg">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Meeting Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredMeetings.map((meeting) => (
                    <tr key={meeting._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {meeting.firstName} {meeting.lastName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{meeting.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{meeting.phoneNumber}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {format(new Date(meeting.meetingDate), 'dd/MM/yyyy')} at {meeting.meetingTime}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getMeetingStatusColor(meeting.status)}`}>
                          {meeting.status || 'scheduled'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col space-y-2">
                          {/* Payment Information Button */}
                          <button
                            onClick={() => handleEmailAction(meeting._id, 'payment')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-blue-600 text-white rounded-md text-sm hover:bg-blue-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Meeting Delayed'
                            )}
                          </button>

                          {/* Stripe Payment Links */}
                          <button
                            onClick={() => handleEmailAction(meeting._id, 'stripeBasic')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Send Basic Plan Link (£99)'
                            )}
                          </button>

                          <button
                            onClick={() => handleEmailAction(meeting._id, 'stripePro')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Send Pro Plan Link (£399)'
                            )}
                          </button>

                          <button
                            onClick={() => handleEmailAction(meeting._id, 'stripeElite')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Send Elite Plan Link (£799)'
                            )}
                          </button>

                          {/* Payment Status Emails */}
                          <button
                            onClick={() => handleEmailAction(meeting._id, 'paymentConfirmation')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-green-600 text-white rounded-md text-sm hover:bg-green-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Send Payment Confirmation'
                            )}
                          </button>

                          <button
                            onClick={() => handleEmailAction(meeting._id, 'paymentReminder')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-purple-600 text-white rounded-md text-sm hover:bg-purple-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Payment Reminder'
                            )}
                          </button>

                          {/* Meeting Status Emails */}
                          <button
                            onClick={() => handleEmailAction(meeting._id, 'noShow')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-yellow-600 text-white rounded-md text-sm hover:bg-yellow-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'No Show Email'
                            )}
                          </button>

                          <button
                            onClick={() => handleEmailAction(meeting._id, 'followUp')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-green-600 text-white rounded-md text-sm hover:bg-green-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Follow Up Email'
                            )}
                          </button>

                          <button
                            onClick={() => handleEmailAction(meeting._id, 'cancel')}
                            disabled={isEmailSending[meeting._id] || meeting.status === 'cancelled'}
                            className="px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-700 disabled:opacity-50 flex justify-center items-center"
                          >
                            {isEmailSending[meeting._id] ? (
                              <><span className="animate-spin mr-2">⟳</span>Sending...</>
                            ) : (
                              'Cancel Meeting'
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}