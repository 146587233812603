import './App.css';
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Layout from './Layout';
import Home from './pages/Home';
import Error404 from './features/banners/Error404';
import AtsChecker from './pages/AtsChecker';
import AtsCheckerResult from "./features/atschecker/AtsCheckerResults"
import PricingPage from './pages/PricingPage';
import AtsReportPage from './pages/AtsReportPage';
import LegalPage from "../src/features/legal/LegalPage"
import CaseStudies from "../src/pages/CaseStudies"
import BlogPage from "../src/pages/BlogPage"
import AtsHow from "../src/features/blog/AtsHow"
import JobDataPage from './pages/JobDataPage';
import SingleJobView from './features/jobdata/SingleJobView'
import PageContactForm from './features/contactform/PageContactForm';
import LoginPage from "../src/components/admin/LoginPage"
import Dashboard from "../src/components/admin/Dashboard"

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "/atschecker",
        element: <AtsChecker />,
      },
      {
        path: "/atschecker/result",
        element: <AtsCheckerResult />,
      },
      {
        path: "/atsreport",
        element: <AtsReportPage />,
      },
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/legal",
        element: <LegalPage />,
      },
      {
        path: "/casestudies",
        element: <CaseStudies/>,
      },
      {
        path: "/blog",
        element: <BlogPage />,
      },
      { 
        path: "/atshow", 
        element: <AtsHow /> 
      },
      {
        path: "/partnerjobs",
        element: <JobDataPage/>
      },
      {
        path: "/partnerjobs/:jobId",
        element: <SingleJobView />
      },
      {
        path: "/bookameeting",
        element: <PageContactForm/>
      },
      {
        path: "/admin",
        element: <LoginPage/>
      },
      { path: "/admin/dashboard", element: <Dashboard/> },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;