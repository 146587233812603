import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PageContactForm() {
  const navigate = useNavigate();
  const [selectedActionState, setSelectedActionState] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allBookedSlots, setAllBookedSlots] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    action: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchAllBookedSlots();
  }, [selectedDate]);

  const fetchAllBookedSlots = async () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      try {
        const response = await fetch(`/api/bookedSlots?date=${formattedDate}`);
        if (response.ok) {
          const data = await response.json();
          setAllBookedSlots({ [formattedDate]: data });
        }
      } catch (error) {
        console.error("Error fetching booked slots:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setSelectedFile(file);
    } else {
      alert("Please select a PDF or DOCX file.");
      e.target.value = null;
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 9; i < 19; i++) {
      slots.push(`${i.toString().padStart(2, '0')}:00`);
      slots.push(`${i.toString().padStart(2, '0')}:30`);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  const isDateDisabled = (date) => {
    const day = date.getDay();
    const dayOfWeek = date.getDay();
    const isDisabledDay = [2,3,4].includes(dayOfWeek);
    return isDisabledDay;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    if (!selectedDate || !selectedTime || !selectedActionState) {
      alert("Please select a date, time, and action for the meeting.");
      setIsLoading(false);
      return;
    }
  
    const meetingDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTime.split(':');
    meetingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    const ukDateTime = DateTime.fromISO(`${selectedDate.toISOString().split('T')[0]}T${selectedTime}`, { zone: 'Europe/London' });
    const isoString = ukDateTime.toISO();
  
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phoneNumber', formData.phoneNumber);
    formDataToSend.append('message', formData.message);
    formDataToSend.append('action', selectedActionState);
    formDataToSend.append('meetingDateTime', isoString);
    formDataToSend.append('selectedTime', selectedTime);
    if (selectedFile) {
      formDataToSend.append('resume', selectedFile);
    }
  
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        body: formDataToSend,
      });
  
      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form. Please try again.");
    }
  
    setIsLoading(false);
  };

  if (showSuccessMessage) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <CheckCircleIcon className="mx-auto h-12 w-12 text-green-500" />
          <h2 className="mt-4 text-2xl font-bold text-gray-900">Booking Confirmed!</h2>
          <p className="mt-2 text-gray-600">
            Thank you for booking a meeting with us. We'll send you an email confirmation shortly!
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Redirecting you to the home page...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-7xl">
              {/* Contact info */}
              <div className="relative text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Get in touch
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  We aim to reply to all of our clients within 24 hours. Please
                  feel free to contact us with any questions.
                </p>
                <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                  <div className="flex justify-center gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Address</span>
                      <BuildingOffice2Icon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                     
                      Newbury, England
                    </dd>
                  </div>
                  <div className="flex justify-center gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="hover:text-gray-900"
                        href="tel:+44 (775) 487-8111"
                      >
                        +44 (775) 487-8111
                      </a>
                    </dd>
                  </div>
                  <div className="flex justify-center gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="hover:text-gray-900"
                        href="mailto:hello@example.com"
                      >
                        help@ukworkx.com
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
              <form onSubmit={handleSubmit} className="mt-16">
  <div className="mx-auto max-w-xl">
    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
      <div>
        <label
          htmlFor="first-name"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          First name
        </label>
        <div className="mt-2.5">
          <input
            type="text"
            name="firstName"
            id="first-name"
            autoComplete="given-name"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={formData.firstName}
            onChange={handleChange}
            required
            placeholder="Enter your first name"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="last-name"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          Last name
        </label>
        <div className="mt-2.5">
          <input
            type="text"
            name="lastName"
            id="last-name"
            autoComplete="family-name"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={formData.lastName}
            onChange={handleChange}
            required
            placeholder="Enter your last name"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="email"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          Email
        </label>
        <div className="mt-2.5">
          <input
            type="email"
            name="email"
            id="email"
            autoComplete="email"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email address"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="phone-number"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          Phone number
        </label>
        <p className="text-red-700 text-xs italic font-bold">Use a UK phone number only. If you are not located in the UK, We will not be able to help you.</p>
        <div className="mt-2.5">
          <input
            type="tel"
            name="phoneNumber"
            id="phone-number"
            autoComplete="tel"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            placeholder="Enter your phone number (UK only)"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="message"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          Message
        </label>
        <div className="mt-2.5">
          <textarea
            name="message"
            id="message"
            rows={4}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={formData.message}
            onChange={handleChange}
            required
            placeholder="Please add the following information: Your University, Course, Your job preferences, and any other information you think is relevant."
          />
        </div>
      </div>

      {/* Action dropdown */}
      <div className="sm:col-span-2">
        <label
          htmlFor="action"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          Action
        </label>
        <div className="mt-2.5">
          <Menu
            as="div"
            className="relative inline-block text-left w-full"
          >
            <div>
              <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {selectedActionState
                  ? selectedActionState
                  : "Please select an action"}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedActionState(
                            "I want to Book a demo"
                          );
                          setFormData({
                            ...formData,
                            action: "I want to Book a demo",
                          });
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        I want to Book a demo
                      </div>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedActionState(
                            "Help and support team"
                          );
                          setFormData({
                            ...formData,
                            action: "Help and support team",
                          });
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        Help and support team
                      </div>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedActionState(
                            "I want to subscribe to the basic plan"
                          );
                          setFormData({
                            ...formData,
                            action:
                              "I want to subscribe to the basic plan",
                          });
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        I want to subscribe to the basic plan
                      </div>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedActionState(
                            "I want to subscribe to the essential plan"
                          );
                          setFormData({
                            ...formData,
                            action:
                              "I want to subscribe to the essential plan",
                          });
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        I want to subscribe to the essential plan
                      </div>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedActionState(
                            "I want to subscribe for the ultimate plan"
                          );
                          setFormData({
                            ...formData,
                            action:
                              "I want to subscribe for the ultimate plan",
                          });
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        I want to subscribe for the ultimate plan
                      </div>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>

    <div className="sm:col-span-2 mt-5">
    <label htmlFor="meeting-date" className="block text-sm font-semibold leading-6 text-gray-900">
      Meeting Date
    </label>
    <label htmlFor="meeting-date" className="block text-xs font-bold italic leading-6 text-red-700">
      Please note that some days have already been fully booked. Please select a date and time that is available.
    </label>
    <div className="mt-2.5">
    <DatePicker
  selected={selectedDate}
  onChange={(date) => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    setSelectedDate(localDate);
    setSelectedTime(null);
    fetchAllBookedSlots();
  }}
  minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
  dateFormat="dd/MM/yyyy"
  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
  required
  filterDate={date => !isDateDisabled(date)}
  placeholderText="Select a date"
/>
    </div>
  </div>
  <div className="sm:col-span-2 mt-5">
  <label htmlFor="meeting-time" className="block text-sm font-semibold leading-6 text-gray-900">
    Meeting Time
  </label>
  <div className="mt-2.5">
    <select
      value={selectedTime}
      onChange={e => setSelectedTime(e.target.value)}
      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
      required
    >
      <option value="">Select a time</option>
      {timeSlots.map(slot => {
  const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null;
  const isBooked = formattedDate && allBookedSlots[formattedDate]?.includes(slot);
  // console.log(`Slot ${slot} on ${formattedDate} is booked:`, isBooked);
  return (
    <option 
      key={slot} 
      value={slot} 
      disabled={isBooked}
      className={isBooked ? 'text-gray-400 italic' : ''}
    >
      {slot} {isBooked ? '(Already Booked)' : ''}
    </option>
  );
})}
    </select>
  </div>
</div>

<div className="sm:col-span-2 mt-6">
  <label htmlFor="resume" className="block text-sm font-semibold leading-6 text-gray-900">
    Upload Resume (PDF or DOCX) (Optional)
  </label>
  <div className="mt-2.5">
    <input
      type="file"
      id="resume"
      name="resume"
      accept=".pdf,.docx"
      onChange={handleFileChange}
      className="block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-md file:border-0
        file:text-sm file:font-semibold
        file:bg-green-50 file:text-green-700
        hover:file:bg-green-100"
    />
  </div>
</div>

    {/* Submit button */}
    <div className="mt-8 flex justify-end">
    <button
  type="submit"
  className="rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
  disabled={isLoading}
>
  {isLoading ? (
    <div className="flex items-center justify-center">
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      Submitting...
    </div>
  ) : (
    "Send message"
  )}
</button>
    </div>
  </div>
</form>
            </div>
      </div>
  )
}
