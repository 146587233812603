import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { Briefcase, MapPin, PoundSterling, Clock, Check } from 'lucide-react';
import { jobs } from './JobsData';

const JobData = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);

  // Load applied jobs from localStorage
  useEffect(() => {
    const appliedJobsCookie = localStorage.getItem('appliedJobs');
    if (appliedJobsCookie) {
      setAppliedJobs(JSON.parse(appliedJobsCookie));
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="mb-12 text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">Exclusive Visa Sponsored Jobs By Our Partner Companies</h1>
        <div className="max-w-3xl mx-auto">
          <p className="text-lg text-gray-600 mb-6">
            We are the UK's premier platform connecting international talent with visa-sponsoring employers. 
            Our dedicated team handles the complete application process, making international hiring seamless and efficient.
          </p>
          <div className="flex justify-center gap-12 mb-8">
            <div className="text-center">
              <div className="text-3xl font-bold text-indigo-600">1,500+</div>
              <div className="text-gray-600">Partner Employers</div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-indigo-600">8,000+</div>
              <div className="text-gray-600">International Students</div>
            </div>
          </div>
          <div className="bg-gradient-to-r from-violet-50 to-indigo-50 rounded-lg p-6">
            <p className="text-gray-700">
              Want to advertise your positions with us? Reach out at{' '}
              <a 
                href="mailto:employers@ukworkx.com" 
                className="text-indigo-600 font-medium hover:text-indigo-700 transition-colors"
              >
                employers@ukworkx.com
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Job Listings */}
      <div className="grid gap-6">
        {jobs.map((job) => (
          <JobCard 
            key={job.id}
            job={job}
            isApplied={appliedJobs.includes(job.id)}
          />
        ))}
      </div>
    </div>
  );
};

const JobCard = ({ job, isApplied }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-lg shadow-md p-6 border border-gray-200 hover:border-indigo-500 transition-colors"
    >
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <Link 
            to={`/partnerjobs/${job.slug}`}
            className="inline-block text-xl font-semibold text-gray-900 mb-2 hover:text-indigo-600 transition-colors"
          >
            {job.title}
          </Link>
          <p className="text-gray-600 mb-4">{job.company}</p>
          
          <div className="flex flex-wrap gap-4 text-sm text-gray-500 mb-4">
            <div className="flex items-center gap-1">
              <MapPin size={16} />
              {job.location}
            </div>
            <div className="flex items-center gap-1">
              <PoundSterling size={16} />
              {job.salary}
            </div>
            <div className="flex items-center gap-1">
              <Briefcase size={16} />
              {job.type}
            </div>
            <div className="flex items-center gap-1">
              <Clock size={16} />
              {job.posted}
            </div>
          </div>

          <p className="text-gray-700 mb-4 line-clamp-2">{job.description}</p>
          
          <div className="space-y-2">
            {job.requirements.slice(0, 2).map((req, index) => (
              <div key={index} className="flex items-start gap-2 text-sm text-gray-600">
                <div className="mt-1">•</div>
                {req}
              </div>
            ))}
            {job.requirements.length > 2 && (
              <Link 
                to={`/partnerjobs/${job.slug}`}
                className="text-sm text-indigo-600 hover:text-indigo-700"
              >
                View all requirements...
              </Link>
            )}
          </div>
        </div>
        
        <div className="ml-6">
          {isApplied ? (
            <div className="flex items-center text-green-600 font-medium">
              <Check size={20} className="mr-1" />
              Applied
            </div>
          ) : (
            <Link
              to={`/partnerjobs/${job.slug}`}
              className="px-4 py-2 bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium rounded-md hover:from-violet-700 hover:to-indigo-700 transition-colors whitespace-nowrap"
            >
              View & Apply
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default JobData;